import Vue from "vue";
import titleMixin from "@/mixins/title";
import { setCurrencyCode } from "@syncfusion/ej2-base";
export default Vue.extend({
    mixins: [titleMixin],
    data() {
        return {
            title: this.$t("account.login.title"),
            username: "",
            password: "",
            rememberMe: false,
            validationOptions: {
                rules: {
                    username: {
                        required: true,
                        email: true
                    },
                    password: {
                        required: true,
                        minLength: 8
                    }
                }
            }
        };
    },
    methods: {
        async login() {
            await this.$store.dispatch("account/login", {
                username: this.username,
                password: this.password,
                keepLoggedIn: this.rememberMe
            });
            try {
                if (this.$store.getters["account/hasMultipleOffices"] === false) {
                    const officeId = this.$store.getters["account/getSingleOfficeId"];
                    const office = await this.$store.dispatch("office/get", {
                        id: officeId
                    });
                    if (office.currencyCode != null)
                        setCurrencyCode(office.currencyCode);
                }
            }
            catch {
                /* empty */
            }
        }
    }
});
